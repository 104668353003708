<div *ngIf="router.url !== '/'" class="special-offer-container">
    <div *ngIf="packages.length > 0" class="published">
        <div class="pub-container">
            <div class="pubb">
                <h3>{{ homeConfig.name | uppercase }}</h3>
            </div>
            <div class="free-delivery">
                <h3>
                    {{ 'shoppingCart.freeDelivery' | translate }}
                    <a href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7" target="_blank"
                        ><mat-icon>info</mat-icon></a
                    >
                </h3>
            </div>
            <div class="free-delivery-mobile">
                <h3>
                    {{ 'shoppingCart.freeDelivery' | translate }}
                    <a href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7" target="_blank"
                        ><mat-icon>info</mat-icon></a
                    >
                </h3>
            </div>
        </div>
    </div>
    <div class="materials-container-special-offer">
        <div class="material-container-published">
            <!-- <div class="books-container"> -->
            <div *ngFor="let package of packages; let i = index" class="book">
                <div class="books">
                    <a (click)="getId(package)" routerLink="{{ package.routerLink }}"><img [src]="package.imageUrl" /></a>
                </div>
                <div class="book-infos">
                    <p>{{ package.packageName }} csomag</p>
                    <s> {{ package.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }} </s>
                    <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }" [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        {{ 'price.discountedPrice' | translate }} {{ package.packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
                <div class="shopping-cart">
                    <button (click)="addToCartPackage(package?.routerLink!)">{{ 'shoppingCart.putIntoCart' | translate }}</button>
                </div>
                <div class="book-border-container"></div>
                <!-- </div> -->
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>

<div *ngIf="router.url === '/'" class="special-offer-container-home">
    <div *ngIf="packages.length > 0" class="published-home">
        <div class="pub-home">
            <h3>{{ homeConfig.name | uppercase }}</h3>
        </div>
    </div>
    <div class="materials-container-special-offer">
        <div class="material-container-published">
            <!-- <div class="books-container"> -->
            <div *ngFor="let package of packages; let i = index" class="book">
                <div class="books">
                    <a (click)="getId(package)" routerLink="/specialoffer/{{ package.routerLink }}"><img [src]="package.imageUrl" /></a>
                </div>
                <div class="book-infos">
                    <p>{{ package.packageName }} csomag</p>
                    <s> {{ package.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }} </s>
                    <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }" [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        {{ 'price.discountedPrice' | translate }} {{ package.packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
                <div class="shopping-cart">
                    <button (click)="addToCartPackage(package?.routerLink!)">{{ 'shoppingCart.putIntoCart' | translate }}</button>
                </div>
                <div class="book-border-container"></div>
                <!-- </div> -->
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
