import { Component, Inject, OnInit } from '@angular/core';
import { PublicationService } from '../publication/publication.service';
import { Router } from '@angular/router';
import { ApiRequestService, GoogleTagManagerService } from '@prekog/pw-common';
import { Material } from '../publication/material';
import { GetAvailableProductsResponse } from './getavailableproductsresponse';
import { GetPreordersResponse } from '../preorders/getpreordersresponse';
import { HomeConfig } from '../home/home-config';
import { FindHomeConfigResponse } from './findhomeconfigresponse';
import { FindHomeConfigRequest } from './findhomeconfigrequest';

@Component({
    selector: 'pw-published',
    templateUrl: './published.component.html',
    styleUrls: ['./published.component.scss']
})
export class PublishedComponent implements OnInit {
    getPublishedProductsUrl = '/store/get-available-products';

    availableProducts: Material[] = [];

    orderableProducts: Material[] = [];

    filteredPublication: Material[] = [];

    storeBaseUrl = '';

    materialsHome: Material[] = [];

    getPreordersUrl = '/store/get-preorders';

    preorderMaterials: Material[] = [];

    getHomeConfigByComponentUrl = '/websiteconfigurator/find-homeconfig';

    homeConfig: HomeConfig = {};

    configuratorBaseUrl = '';

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredPublication = this.performFilter(value);
    }

    constructor(
        private _publicationService: PublicationService,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _googleTagManagerService: GoogleTagManagerService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, GetPreordersResponse>(this.storeBaseUrl, true, {}, true, this.getPreordersUrl)
            .subscribe((response) => {
                this.preorderMaterials = response.productList as Material[];
                this.preorderMaterials.forEach((preorderMaterial) => {
                    preorderMaterial.oldPrice = preorderMaterial.originalPrice;
                    preorderMaterial.originalPrice = preorderMaterial.preorderPrice;
                    preorderMaterial.typeOfPrice = 'Előrendelési ár:';
                    this.orderableProducts.push(preorderMaterial);
                });
                this._apiRequestService
                    .sendRequest<{}, GetAvailableProductsResponse>(this.storeBaseUrl, true, {}, true, this.getPublishedProductsUrl)
                    .subscribe((response) => {
                        this.availableProducts = response.productList as Material[];
                        this.availableProducts.forEach((availableMaterial, index) => {
                            if (availableMaterial.webshopPriceActive) {
                                availableMaterial.oldPrice = availableMaterial.originalPrice;
                                availableMaterial.originalPrice = availableMaterial.webshopPrice;
                                if (availableMaterial.discountLimitedUntil === null) {
                                    availableMaterial.typeOfPrice = 'Kiadói ár:';
                                }
                                else {
                                    availableMaterial.typeOfPrice = 'Kötött ár:';
                                }
                            } else if (availableMaterial.discountPriceActive) {
                                availableMaterial.oldPrice = availableMaterial.originalPrice;
                                availableMaterial.originalPrice = availableMaterial.discountPrice;
                                availableMaterial.typeOfPrice = 'Akciós ár:';
                            }

                             else {
                                if (availableMaterial.discountLimitedUntil === null) {
                                    availableMaterial.typeOfPrice = 'Ár:';
                                }
                                else {
                                    availableMaterial.typeOfPrice = 'Kötött ár:';
                                }
                            }

                            this.orderableProducts.push(availableMaterial);
                        });

                        this.orderableProducts.forEach((orderableProduct, index) => {
                            if (index < 4) {
                                this.materialsHome.push(orderableProduct);
                            }
                        });
                        this.filteredPublication = this.orderableProducts;
                        this._googleTagManagerService.viewProductItemList(this.availableProducts);
                        this.getHomeConfigName();
                    });
            });
    }

    getHomeConfigName() {
        const findHomeConfigRequest: FindHomeConfigRequest = {
            component: 'pw-published'
        };
        this._apiRequestService
            .sendRequest<FindHomeConfigRequest, FindHomeConfigResponse>(
                this.configuratorBaseUrl,
                true,
                findHomeConfigRequest,
                true,
                this.getHomeConfigByComponentUrl
            )
            .subscribe((response) => {
                this.homeConfig = response.homeConfig as HomeConfig;
            });
    }
    performFilter(filterBy: string): Material[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.orderableProducts.filter(
            (publication: Material) =>
                publication.title?.toLocaleLowerCase().includes(filterBy) || publication.author?.toLocaleLowerCase().includes(filterBy)
        );
    }

    addToCart(material: Material) {
        if (!sessionStorage.getItem('token') && material.hasPreorder) {
            sessionStorage.setItem('material', JSON.stringify(material));
            this._publicationService.openLogin(material);
        } else {
            this._publicationService.addToCart(material);
        }
    }

    orderbyAscPrice() {
        this.filteredPublication = this.filteredPublication.sort((a, b) => a.originalPrice! - b.originalPrice!);
    }
    orderbyDescPrice() {
        this.filteredPublication = this.filteredPublication.sort((a, b) => b.originalPrice! - a.originalPrice!);
    }
    orderbyTitleAbc() {
        this.filteredPublication = this.filteredPublication.sort((a, b) => a.title!.localeCompare(b.title!));
    }
    orderByAuthorAbc() {
        this.filteredPublication = this.filteredPublication.sort((a, b) => a.author!.localeCompare(b.author!));
    }
    orderByNewest() {
        this.filteredPublication = this.filteredPublication.sort((a, b) => b.id! - a.id!);
    }

    showAllBooks() {
        this.filteredPublication = JSON.parse(sessionStorage.getItem('products') as string) as Material[];
    }
}
