import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiRequestService, GoogleTagManagerService, ShoppingcartService } from '@prekog/pw-common';
import { Observable } from 'rxjs/internal/Observable';
import { BookExtractPopupComponent } from '../book-extract-popup/book-extract-popup.component';
import { PackageProductRequest } from '../discountedproduct/packageproductrequest';
import { PackageProductResponse } from '../discountedproduct/packageproductresponse';
import { Material } from '../publication/material';
import { ProductsResponse } from '../publication/productsresponse';
import { Package } from '../specialoffer/package';
import { GetProfessionalMaterialContentUseCase } from './get-professional-material-content.usecase';
import { GetProfessionalMaterialContentRequest } from './get-professional-material-content.usecase-request';
import { GetProfessionalMaterialContentResponse } from './get-professional-material-content.usecase-response';
import { GetPackagesForBookRequest } from './getpackagesforbookrequest';
import { GetPackagesForBookResponse } from './getpackagesforbookresponse';
import { Serie } from './serie';
import { SerieResponse } from './serie-response';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'pw-popuptxt',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';
    dialogConfiger: MatDialogConfig = {
        width: '100%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    filteredPublication: Material[] = [];

    getProductsUrl = '/store/get-products';

    getPackegesForBookUrl = '/store/get-packagesforaproduct';

    getSerieForABookUrl = '/store/get-series';

    id = 0;

    material?: Material = {};

    preOrderData = '';

    publication: Material[] = [];

    routerLink = '';

    textData = '';

    frontendUrl = window.location.origin;

    cthulhuOrderNumber = 8;

    eurotrashOrderNumber = 12;

    orderBackendUrl = '';

    getPackagesForBookRequest: GetPackagesForBookRequest = {};

    getPackagesForBookResponse: GetPackagesForBookResponse = {};

    packageOffer: Package[] = [];

    packageOfferElement: Package = {};

    packageProductsUrl = '/store/get-packageproduct';

    packageProductRequest: PackageProductRequest = {};

    products: Material[] = [];

    packageOriginalPrice = 0;

    packagePrice = 0;

    package: Package = {};

    storeBackendUrl = '';

    serieList: Serie[] = [];

    serie: Serie = {};

    serieResponse: SerieResponse = {};

    constructor(
        private _shoppingcartService: ShoppingcartService,
        private _getProfessionalMaterialContentUseCase: GetProfessionalMaterialContentUseCase,
        private _apiRequestService: ApiRequestService,
        private _metaService: Meta,
        private _router: Router,
        private _http: HttpClient,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _googleTagManagerService: GoogleTagManagerService,
        private readonly _domSanitizer:DomSanitizer
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBackendUrl = _storeBackendUrl;
    }
    //isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        this.routerLink = lastSegmentOfUrl as string;
        this._metaService.updateTag({ property: 'og:url', content: this.frontendUrl + this._router.url });
        //TODO create a backend service just to get response of one product!!!
        this._apiRequestService.sendRequest(this.storeBackendUrl, true, {}, true, this.getProductsUrl).subscribe((productList) => {
            const productsLists = productList as ProductsResponse;
            this.publication = productsLists.productList as Material[];
            this.initializeProducts();

            this._googleTagManagerService.viewProductItem(this.material!);

            this.getPackagesForBookRequest = {
                productId: this.material?.id
            };
            this._apiRequestService
                .sendRequest(this.storeBackendUrl, true, this.getPackagesForBookRequest, true, this.getPackegesForBookUrl)
                .subscribe((packageList) => {
                    this.getPackagesForBookResponse = packageList as GetPackagesForBookResponse;
                    if (this.getPackagesForBookResponse.packageList?.length! > 0) {
                        this.packageOffer = this.getPackagesForBookResponse.packageList as Package[];
                        //Showing random package
                        const randomIndex = Math.floor(Math.random() * this.packageOffer.length);

                        this.packageOfferElement = this.packageOffer[randomIndex];

                        this.packageProductRequest = {
                            routerLink: this.packageOffer[randomIndex].routerLink
                        };

                        this._apiRequestService
                            .sendRequest(this.storeBackendUrl, true, this.packageProductRequest, true, this.packageProductsUrl)
                            .subscribe((packageProductResponse) => {
                                const response = packageProductResponse as PackageProductResponse;
                                //this.packageObject = response.packageObject as Package;
                                this.products = response.products as Material[];

                                this.products.forEach((product) => {
                                    this.packageOriginalPrice += product.originalPrice!;
                                    this.packagePrice += product.packagePrice!;
                                });
                            });
                    }
                });

            //getSerie
            this._apiRequestService.sendRequest(this.storeBackendUrl, true, {}, true, this.getSerieForABookUrl).subscribe((response) => {
                this.serieResponse = response as SerieResponse;
                this.serieList = this.serieResponse.serieList as Serie[];

                this.serieList.forEach((serie) => {
                    if (this.material?.serieId === serie.id) {
                        this.serie = serie;
                    }
                });
            });
        });
    }

    loadSelectedMaterial(selectedMaterial: Material) {
        const request: GetProfessionalMaterialContentRequest = { materialFileName: selectedMaterial.txt as string };
        (this._getProfessionalMaterialContentUseCase.execute(request) as Observable<GetProfessionalMaterialContentResponse>).subscribe({
            next: (useCaseResponse: GetProfessionalMaterialContentResponse) => {
                selectedMaterial.textData = useCaseResponse.content;
            }
        });
    }

    addToCart(material: Material) {
        if (!sessionStorage.getItem('token') && material.hasPreorder) {
            this._shoppingcartService.openLogin(material);
        } else {
            this._shoppingcartService.addToCart(material);
        }
    }

    initializeProducts() {
        this.filteredPublication = this.publication;

        this.publication.forEach((value, index) => {
            if (value.webshopPriceActive) {
                value.oldPrice = value.originalPrice;
                value.originalPrice = value.webshopPrice;
            }
            if (value.discountPriceActive) {
                value.oldPrice = value.originalPrice;
                value.originalPrice = value.discountPrice;
            }
            if (value.hasPreorder) {
                value.oldPrice = value.originalPrice;
                value.originalPrice = value.preorderPrice;
            }
            //TODO it is because of facebook link that I have used includes not ==, fb puts a token after url
            if (this.routerLink.includes(value.routerLink as string)) {
                this.id = index;
                this.material = this.publication[this.id];
                this.material.txt = this._domSanitizer.bypassSecurityTrustHtml(this.material.txt!) as string;
                const imageUrl = this.publication[this.id].url?.substring(1);
                this._metaService.updateTag({ property: 'og:image', content: (this.frontendUrl + imageUrl) as string });
            }
        });
        this.loadSelectedMaterial(this.filteredPublication[this.id]);
        if (this.filteredPublication[this.id].hasPreorder) {
            this._http.get(this.filteredPublication[this.id].preOrderTxt as string, { responseType: 'text' }).subscribe((preOrderData) => {
                this.preOrderData = preOrderData;
            });
        }

        this.routerLink = this.publication[this.id].routerLink as string;
    }
    openBookExtract(selectedMaterial: Material) {
        this.dialogConfiger.data = selectedMaterial;
        const popupDialog = this._matDialog.open(BookExtractPopupComponent, {
            data: selectedMaterial,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw'
        });
    }

    openPdf(bookExtractUrl: string) {
        window.open(bookExtractUrl);
    }
}
