<div *ngIf="router.url !== '/'" class="search-bar-container">
    <div class="container">
        <div class="free-delivery-mobile">
            <h3>
                {{ 'shoppingCart.freeDelivery' | translate }}
                <a href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7" target="_blank"><mat-icon>info</mat-icon></a>
            </h3>
        </div>
        <div class="search-bar">
            <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
            <input [(ngModel)]="listFilter" type="search" placeholder="Szerző, cím neve" />
        </div>
        <div class="free-delivery">
            <h3>
                {{ 'shoppingCart.freeDelivery' | translate }}
                <a href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7" target="_blank"><mat-icon>info</mat-icon></a>
            </h3>
        </div>
    </div>
    <div class="mat-select">
        <mat-form-field>
            <mat-label>Rendezés</mat-label>
            <mat-select id="statuses">
                <mat-option (click)="orderByNewest()" value="Szerző szerint A-Z">{{ 'materials.newestFirst' | translate }}</mat-option>
                <mat-option (click)="orderbyAscPrice()" value="Ár szerint növekvő">{{ 'materials.ascendingPrice' | translate }}</mat-option>
                <mat-option (click)="orderbyDescPrice()" value="Ár szerint csökkenő">{{
                    'materials.descendingPrice' | translate
                }}</mat-option>
                <mat-option (click)="orderbyTitleAbc()" value="Cím szerint A-Z">{{ 'materials.titleA_Z' | translate }}</mat-option>
                <mat-option (click)="orderByAuthorAbc()" value="Szerző szerint A-Z">{{ 'materials.authorA_Z' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div *ngIf="router.url !== '/'" class="materials-container">
    <div *ngIf="orderableProducts.length > 0" class="soon-published">
        <div class="soon-pub">
            <h3>{{ homeConfig.name | uppercase }}</h3>
        </div>
    </div>
    <div *ngIf="orderableProducts.length > 0" class="soon-container-home">
        <div class="main">
            <div *ngFor="let publication of filteredPublication; let i = index" class="material-container-soon">
                <div class="books">
                    <a routerLink="{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                </div>
                <div class="book-infos">
                    <p>{{ publication.author }}</p>
                    <p>{{ publication.title }}</p>
                    <p *ngIf="!publication.isAvailable" class="available">
                        {{ 'materials.expectedPublicationDate' | translate }} {{ publication.available | date }}
                    </p>
                    <p *ngIf="publication.isAvailable" class="available">
                        {{ 'materials.publishedBook' | translate }} {{ publication.available | date }}
                    </p>
                    <span *ngIf="publication.webshopPriceActive || publication.discountPriceActive || publication.hasPreorder">
                        Ár a könyvön: {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </span>
                    <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        {{ publication.typeOfPrice }} {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
                <div *ngIf="!publication.hasPreorder" class="shopping-cart">
                    <button *ngIf="publication.quantityOfSupply !== 0" (click)="addToCart(publication)">
                        {{ 'shoppingCart.putIntoCart' | translate }}
                    </button>
                    <div *ngIf="publication.quantityOfSupply === 0" class="inactive">
                        <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                            {{ 'shoppingCart.putIntoCart' | translate }}
                        </button>
                        <span>Elfogyott</span>
                    </div>
                </div>
                <div *ngIf="publication.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(publication)" class="preorder">ELŐRENDELÉS</button>
                </div>
                <div class="book-border-container"></div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>

<div *ngIf="router.url === '/'" class="materials-container">
    <div *ngIf="materialsHome.length > 0" class="soon-published-home">
        <div class="soon-pub-home">
            <h3>{{ homeConfig.name | uppercase }}</h3>
        </div>
    </div>
    <div *ngIf="materialsHome.length > 0" class="soon-container-home">
        <div class="main">
            <div *ngFor="let publication of materialsHome; let i = index" class="material-container-soon">
                <div class="books">
                    <a routerLink="/book/{{ publication.routerLink }}"><img [src]="publication.url" /></a>
                </div>
                <div class="book-infos">
                    <p>{{ publication.author }}</p>
                    <p>{{ publication.title }}</p>
                    <p class="available" *ngIf="!publication.isAvailable">
                        {{ 'materials.expectedPublicationDate' | translate }} {{ publication.available | date }}
                    </p>
                    <p *ngIf="publication.isAvailable" class="available">
                        {{ 'materials.publishedBook' | translate }} {{ publication.available | date }}
                    </p>
                    <span *ngIf="publication.webshopPriceActive || publication.discountPriceActive || publication.hasPreorder">
                        Ár a könyvön: {{ publication.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </span>
                    <p [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                        {{ publication.typeOfPrice }} {{ publication.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                    </p>
                </div>
                <div *ngIf="!publication.hasPreorder" class="shopping-cart">
                    <button *ngIf="publication.quantityOfSupply !== 0" (click)="addToCart(publication)">
                        {{ 'shoppingCart.putIntoCart' | translate }}
                    </button>
                    <div *ngIf="publication.quantityOfSupply === 0" class="inactive">
                        <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                            {{ 'shoppingCart.putIntoCart' | translate }}
                        </button>
                        <span>Elfogyott</span>
                    </div>
                </div>
                <div *ngIf="publication.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(publication)" class="preorder">ELŐRENDELÉS</button>
                </div>
                <div class="book-border-container"></div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
