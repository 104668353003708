import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Petofi } from './petofi';
import { HttpClient } from '@angular/common/http';
import { HgWellss } from './hgwells';
import { BlogThumbs } from '../blog-thumbs';;
import { Router } from '@angular/router';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ApiRequestService } from '@prekog/pw-common';
import { FindBlogByRouterLinkRequest } from './findblogbyrouterlinkrequest';
import { FindBlogByrouterLinkResponse } from './findblogbyrouterlinkresponse';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    findBlogByRouterLinkUrl = '/websiteconfigurator/find-blog-by-routerlink';

    blogPetofi: Petofi[] = [];

    blogThumbs: BlogThumbs[] = [];

    blog:BlogThumbs = {};

    blogThumbUrl = '../../assets/configurations/blog-thumb.json';

    hgWellsUrl = '../../assets/configurations/hgwells.json';

    petofiUrl = '../../assets/configurations/blog_petofi.json';

    hgWells: HgWellss[] = [];

    routerLink = '';

    isPetofi = false;

    isWells = false;
    isCarr = false;
    isPacsirta = false;
    isKazohinia = false;
    isSzinek = false;
    isOmega = false;

    petofiImg = '';
    pacsirtaImg = '';
    carrImg = '';
    wellsImg = '';
    kazohiniaImg = '';
    szinekImg = '';
    omegaImg = '';

    frontendUrl = window.location.origin;

    downloadFileUrl = '';

    configuratorBaseUrl = '';

    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _metaService: Meta,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private readonly _domSanitizer:DomSanitizer
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.petofiImg = this.downloadFileUrl + '/petofiblog';
        this.pacsirtaImg = this.downloadFileUrl + '/pacsirtablog';
        this.carrImg = this.downloadFileUrl + '/carrblog';
        this.wellsImg = this.downloadFileUrl + '/retrofuturistablog';
        this.kazohiniaImg = this.downloadFileUrl + '/kazohiniablog';
        this.szinekImg = this.downloadFileUrl + '/szinekblog';
        this.omegaImg = this.downloadFileUrl + '/dromegablog';
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        this.routerLink = lastSegmentOfUrl as string;
        this._metaService.updateTag({ property: 'og:url', content: this.frontendUrl + this._router.url });

        const findBlogByRouterLinkRequest: FindBlogByRouterLinkRequest = {
            routerLink:this.routerLink
        }

        this._apiRequestService
            .sendRequest<{}, FindBlogByrouterLinkResponse>(this.configuratorBaseUrl, true, findBlogByRouterLinkRequest, true, this.findBlogByRouterLinkUrl)
            .subscribe((response) => {
                this.blog = response.blog as BlogThumbs;
                this.blog.innerHtml = this._domSanitizer.bypassSecurityTrustHtml(this.blog.innerHtml!) as string;

            });


    }
}
