import { Inject, Injectable } from "@angular/core";
import { ApiRequestService, BusinessLogicError, LogService, ToastmessageService, TranslationService, UseCase, Void } from "@prekog/pw-common";
import { BlogThumbs } from '../../blog-thumbs';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { GetPublishedBlogsResponse } from "./getpublishedblogsresponse";

@Injectable({
    providedIn: 'root'
})

export class GetBlogThumbsUseCase extends UseCase<Void, Observable<BlogThumbs[]>>  {

    bannersBlogUrl = '../../assets/configurations/blog-thumb.json';

    getPublishedBlogsUrl = '/websiteconfigurator/get-published-blogs';

    configuratorBaseUrl = '';

    constructor(_logService: LogService,
        _http: HttpClient,
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService) {
        super(_logService,_http);
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    protected override validate(request:void | Void) {
        //TODO validate if bannersBlogUrl exists or not!!!
    }

    protected override executeBusinessLogic(request: void | Void): void | Observable<BlogThumbs[]> {
        return this._apiRequestService.sendRequest<{},GetPublishedBlogsResponse>(this.configuratorBaseUrl, true, {}, true, this.getPublishedBlogsUrl)
        .pipe(
            map((getpublishedBlogsResponse:GetPublishedBlogsResponse) => {
                if (!getpublishedBlogsResponse.publishedBlogList) {
                    throw new BusinessLogicError("Couldn't read bannerThumbs file content!");
                }
                return getpublishedBlogsResponse.publishedBlogList

            }));
    }




}
