<div class="home-container">
    <div class="about">
        <div *ngFor="let homeConfig of homeConfigList" class="newbooks-home">
            <div *ngIf="homeConfig.active">
                <pw-home-info *ngIf="homeConfig.component === 'pw-home-info'"></pw-home-info>
                <pw-soon-published *ngIf="homeConfig.component === 'pw-soon-published' && homeConfig.active"></pw-soon-published>
                <pw-published *ngIf="homeConfig.component === 'pw-published'"></pw-published>
                <pw-discounted-products *ngIf="homeConfig.component === 'pw-discounted-products'"></pw-discounted-products>
                <pw-packages *ngIf="homeConfig.component === 'pw-packages'"></pw-packages>
            </div>
        </div>
        <div class="blog-container">
            <div class="title-home-blog">
                <div class="title-home-blog-second">
                    <h3>{{ 'home.newsletterBlogs' | translate }}</h3>
                </div>
            </div>
            <div class="image-container">
                <pw-blog-slideshow [bannersBlogUrl]="bannerThumbUrl" [isBannerThumb]="true"></pw-blog-slideshow>
            </div>
        </div>
    </div>
</div>
