<div *ngIf="isBannerThumb === true" class="blog-slideshow-container {{ isBannerThumb }}">
    <div class="blog-slideshow">
        <div class="blog-books">
            <div *ngFor="let banner of bannerActual; let i = index" class="blog-book">
                <a routerLink="blog/{{ banner.routerLink }}"><img [@fade] class="blog-thumb-image" [src]="banner.thumbnailUrl" /></a>
                <div class="book-blog-title">
                    <h4>{{ banner.title }}</h4>
                </div>
            </div>
            <div *ngIf="bannerActual.length < 4" class="placeholder"></div>
        </div>
    </div>
    <div class="buttons-previous-next">
        <div class="button-previous">
            <button class="previous-button"><mat-icon (click)="showPreviousImage()">keyboard_arrow_left</mat-icon></button>
        </div>
        <div class="button-next">
            <button class="next-button"><mat-icon (click)="showNextImage()">keyboard_arrow_right</mat-icon></button>
        </div>
    </div>
</div>

<div *ngIf="isBannerThumb === true" class="blog-slideshow-container-mobile {{ isBannerThumb }}">
    <div class="blog-slideshow-mobile">
        <div class="blog-books-mobile">
            <div class="blog-book-mobile">
                <a routerLink="blog/{{ bannerActualMobile?.routerLink }}"
                    ><img [@fade] class="blog-thumb-image-mobile" [src]="bannerActualMobile?.thumbnailUrl"
                /></a>
                <div class="book-blog-title-mobile">
                    <h4>{{ bannerActualMobile?.title }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-previous-next-mobile">
        <div class="button-previous-mobile">
            <button class="previous-button-mobile"><mat-icon (click)="showPreviousImageMobile()">keyboard_arrow_left</mat-icon></button>
        </div>
        <div class="button-next-mobile">
            <button class="next-button-mobile"><mat-icon (click)="showNextImageMobile()">keyboard_arrow_right</mat-icon></button>
        </div>
    </div>
</div>

<div *ngIf="isBannerThumb === false" class="material-box-container {{ isBannerThumb }}">
    <div class="buttons-previous-next">
        <div class="button-previous">
            <button class="previous-button"><mat-icon (click)="showPreviousImage()">keyboard_arrow_left</mat-icon></button>
        </div>
    </div>
    <div class="material-box-container-second">
        <div *ngFor="let material of materialsActual; let i = index" class="material-box">
            <div class="materials">
                <div class="material-img">
                    <a *ngIf="material?.title !== 'Cthulhu'" routerLink="book/{{ material.routerLink }}"
                        ><img [@fade] src="{{ material.url }}"
                    /></a>
                    <a *ngIf="material?.title === 'Cthulhu'" routerLink="book/{{ material.routerLink }}"
                        ><img class="cthulhu-image" [@fade] src="{{ material.url }}"
                    /></a>
                </div>
                <div class="material-datas">
                    <p>{{ material.author }}</p>
                    <p>{{ material.title }}</p>
                    <s *ngIf="material?.webshopPriceActive">{{ material?.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</s>
                    <h3>{{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3>
                </div>
                <div *ngIf="!material.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(material)">{{ 'shoppingCart.putIntoCart' | translate }}</button>
                </div>
                <div *ngIf="material.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(material)" class="preorder" disabled="true">ELŐRENDELÉS</button>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-previous-next">
        <div class="button-next">
            <button class="next-button"><mat-icon (click)="showNextImage()">keyboard_arrow_right</mat-icon></button>
        </div>
    </div>
</div>

<div *ngIf="isBannerThumb === false" class="material-box-container-mobile {{ isBannerThumb }}">
    <div class="material-box-container-second-mobile">
        <div class="material-box-mobile">
            <div class="materials-mobile">
                <div class="material-img-mobile">
                    <div class="buttons-previous-next">
                        <div class="button-previous">
                            <button class="previous-button">
                                <mat-icon (click)="showPreviousImageMobile()">keyboard_arrow_left</mat-icon>
                            </button>
                        </div>
                    </div>
                    <a routerLink="book/{{ materialsMobileActual?.routerLink }}"><img [@fade] src="{{ materialsMobileActual?.url }}" /></a>
                    <div class="buttons-previous-next">
                        <div class="button-next">
                            <button class="next-button"><mat-icon (click)="showNextImageMobile()">keyboard_arrow_right</mat-icon></button>
                        </div>
                    </div>
                </div>
                <div class="material-datas-mobile">
                    <p>{{ materialsMobileActual?.author }}</p>
                    <p>{{ materialsMobileActual?.title }}</p>
                    <s *ngIf="materialsMobileActual?.webshopPriceActive">{{
                        materialsMobileActual?.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0'
                    }}</s>
                    <h3>{{ materialsMobileActual?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3>
                </div>
                <div *ngIf="!materialsMobileActual?.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(materialsMobileActual!)">KOSÁRBA</button>
                </div>
                <div *ngIf="materialsMobileActual?.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(materialsMobileActual!)" class="preorder" disabled="true">ELŐRENDELÉS</button>
                </div>
            </div>
        </div>
    </div>
</div>
